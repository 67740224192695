import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/universities', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/universities/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/universities/' + data.id, data);
    }
    return Api.post('/universities', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/universities/' + id, formData);
}

const getUniversityGradeConversions = async (universityId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/university/grade/conversions/' + universityId);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/universities/' + id);
}

const getAllPublic = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/universities/public', config);
}

const downloadAsEXcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/universities/excel-export',{...config,responseType:'arraybuffer'})
}

export default {
    getAll,
    update,
    get,
    store,
    getUniversityGradeConversions,
    del,
    getAllPublic,
    downloadAsEXcel
}
