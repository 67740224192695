<template>
	<div>
		<multiselect
			v-model="selected"
			label="text"
			track-by="value"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			:placeholder="$t('select')"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
			@input="handleInput($event)"
			:disabled="disabled">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import DistrictOutlineService from "@/services/DistrictOutlineService";

	export default {
		props: {
			city_id: {
				type: Number,
				default: null
			},
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
			disabled: {
                type: Boolean,
                default: false
            },
		},

		data() {
			return {
				selected: null,
				options: []
			}
		},
		watch: {
			city_id: function (newValue, oldValue) {
				this.getOptions(newValue)
			},
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		created() {
			this.getOptions()
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				this.clearOptions();
				if (this.city_id == null) {
					this.$emit("input", null)
					return;
				}
                DistrictOutlineService.getDistrict(this.city_id)
				                      .then(response => {
					                      const data = response.data.data;
					                      data.forEach((item) => {
						                      this.options.push({
							                      value: item.id,
							                      text: item.name,
						                      });
					                      });
				                      })
				                      .then(() => {
					                      this.selected = this.setSelected(this.value, this.options)
					                      if(this.selected==null || this.selected.length==0){
						                      this.$emit("input", null)
					                      }
				                      })
			},
			clearOptions() {
				this.selected = null
				this.options = []
			}
		}
	}
</script>
